import { mapSetPosition } from './MapPosition'
import { initHomeForm, initSearchForm } from './MapForms'
import { initGeocoder } from './MapForms'
import { createMap } from './MapFunctions'

const store = {
  structures: [],
  farthestStructure: null,
  listedStructureCount: 0,
  markers: [],
  infoWindows: [],
  startLocation: {},
  input: {
    address: '',
    region: ''
  }
}

const mapParams = {
  zoom: {
    max: 16,
    min: 6,
    current: 6
  },
  mapCenter: {
    lng: 2.213749,
    lat: 46.227638
  }
}

const listParams = {
  currentPage: 0,
  limitPerPage: 10,
  pageNb: null
}

function init() {
  mapSetPosition()
  createMap()
  initGeocoder()
  initHomeForm()
  initSearchForm()
}

export default { init }
export { listParams, mapParams, store }
