function init() {
  const form = document.querySelector('[data-test-fagerstrom]')

  if(!form) return
  const baseUrl = window.location.origin
  const resultContainer = document.querySelector('[data-test-fagerstrom-result]')

  form.addEventListener('submit', async function(e){
    e.preventDefault()

    const timeFirstCigaretteValue = document.getElementById('timeFirstCigarette').value
    const numberCigaretteValue = document.getElementById('numberCigarette').value

    const request = new Request(`${baseUrl}/api/result-test-fagerstrom?timeFirstCigarette=${timeFirstCigaretteValue}&numberCigarette=${numberCigaretteValue}`)
    await fetch(request)
      .then(response => {
        if(response.status === 500) {
          return
        }
        return response.text()
      }).then(function (data) {
        if(data !== undefined) {
          resultContainer.innerHTML = data
          resultContainer.scrollIntoView({
            behavior: "smooth"
          })
        }
      })

  })
}
 
export default { init }
