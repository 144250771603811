function getCookieValue(name) {
  let result = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
  return result ? result.pop() : ""
}

function setCookie(cName, cValue, expDays) {
  let date = new Date()
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000))
  const expires = "expires=" + date.toUTCString()
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/"
}

export { getCookieValue, setCookie }
