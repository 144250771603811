import throttle from './../../js/utils/throttle'


const mapResultsContainer = document.querySelector('[data-map-resultsContainer]')

export function mapSetPosition() {
  if (!mapResultsContainer) return

  mapUpdatePosition()

  window.addEventListener('resize', throttle(mapUpdatePosition, 16))
}

function mapUpdatePosition() {
  const mapResultsContent = mapResultsContainer.querySelector('[data-map-resultsContent]')
  const mapContainer = mapResultsContainer.querySelector('[data-results-mapContainer]')
  const resultsList = document.querySelector('[data-map-resultsList]')
  const largeBP = window.matchMedia("(max-width: 1099px)")

  if (largeBP.matches) {
    if (mapContainer.parentNode !== mapResultsContent) {
      resultsList.parentNode.insertBefore(mapContainer, resultsList)
    }
  } else {
    if (mapContainer.parentNode !== mapResultsContainer) {
      mapResultsContainer.appendChild(mapContainer)
    }
  }
}
