import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"

function init() {
  const shops = document.querySelectorAll('[data-shop]')

  if (!shops.length) return

  shops.forEach((shop, index) => {
    const filtersApplyButton = shop.querySelector('[data-filters-apply]')
    const allProducts = shop.querySelectorAll('[data-productCategory]')

    filtersToggle(shop, index)

    filtersApplyButton.addEventListener('click', () => {
      filter(shop, allProducts)
    })
  })
}

function filtersToggle(shop, shopIndex) {
  const filtersButtonToggle = shop.querySelector('[data-filters-toggle]')
  const filtersContent = shop.querySelector('[data-filters-content]')

  const filtersID = `filters-${shopIndex + 1}`
  const mqSmall = window.matchMedia("(max-width: 700px)")

  filtersButtonToggle.setAttribute('aria-controls', filtersID)
  filtersContent.setAttribute('id', filtersID)

  if (mqSmall.matches) {
    filtersButtonToggle.setAttribute('aria-expanded', 'false')
    filtersContent.style.display = "none"

    filtersButtonToggle.addEventListener('click', () => {
      if (filtersButtonToggle.getAttribute('aria-expanded') === 'true') {
        filtersButtonToggle.setAttribute('aria-expanded', 'false')
        filtersContent.style.display = "none"
      } else {
        filtersButtonToggle.setAttribute('aria-expanded', 'true')
        filtersContent.style.display = "block"
      }
    })
  }
}

function filter(shop, allProducts) {
  const productsContainer = shop.querySelector('[data-productsContainer]')
  const filters = shop.querySelectorAll('[data-filter]')
  const activeFilters = []
  let filteredProducts = []

  const filterChecked = (filter) => filter.checked

  if (Array.from(filters).some(filterChecked)) {
    filters.forEach(filter => {
      if (filter.checked) {
        const filterId = filter.getAttribute('data-filter')
        activeFilters.push(filterId)
      }
    })

    allProducts.forEach(product => {
      const productCategory = product.getAttribute('data-productCategory')

      const selectedProductCat = (cat) => cat === productCategory

      if (activeFilters.some(selectedProductCat)) {
        filteredProducts.push(product)
      }
    })
  } else {
    filteredProducts = allProducts
  }

  productsContainer.innerHTML = ''

  filteredProducts.forEach(product => {
    productsContainer.appendChild(product)
  })
}

export default { init }
