import playerModal from "../embed/playerModal/playerModal";

const DATA_MODAL_SELECTOR = '[player-data-modal]'

const modals = document.querySelectorAll(DATA_MODAL_SELECTOR)

function init() {

  if(!modals.length) return
  createInstances()

}

function createInstances() {
  modals.forEach(modal => {
    const modalInstance = new playerModal(modal)
    modalInstance.init()
  });
}

export default { init }
