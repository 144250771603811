function init() {
  const timelineHeaderDropdown = document.querySelector('[data-timelineHeader-dropdown]')

  if (!timelineHeaderDropdown) return

  const dropdownToggle = timelineHeaderDropdown.querySelector('[data-timelineHeaderDropdown-toggle]')
  const dropdownToggleText = dropdownToggle.querySelector('span')
  const dropdownContent = timelineHeaderDropdown.querySelector('[data-timelineHeaderDropdown-content]')

  const closedLabel = dropdownToggle.getAttribute('data-label-closed')
  const openedLabel = dropdownToggle.getAttribute('data-label-opened')

  dropdownToggle.addEventListener('click', function() {
    if (this.getAttribute('aria-expanded') === 'false') {
      this.setAttribute('aria-expanded', 'true')
      dropdownToggleText.innerText = openedLabel
    } else {
      this.setAttribute('aria-expanded', 'false')
      dropdownToggleText.innerText = closedLabel
    }

    if (dropdownContent.getAttribute('aria-hidden') === 'true') {
      dropdownContent.setAttribute('aria-hidden', 'false')
    } else {
      dropdownContent.setAttribute('aria-hidden', 'true')
    }

    dropdownContent.classList.toggle('is-open')
  })
}

export default { init }
