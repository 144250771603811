import { listParams, mapParams, store } from './Map'
import {
  map,
  updateMapCenter,
  updateMapMarkers,
  updateMapInfoWindows,
  updateMapZoom,
  onMarkerClick } from './MapFunctions'


function paginationMarkup() {
  const pagination = document.createElement('div')
  pagination.classList.add('MapResults-pagination')

  let markup = `<div class="Pagination" data-pagination><div class="Pagination-container"><ul class="Pagination-content">`

  if (listParams.currentPage > 0) {
    markup += `<li class="Pagination-item Pagination-item--previous">
      <button class="Pagination-link">
        <svg class="icon icon-arrow-left" aria-hidden="true" focusable="false" width="20" height="20"><use xlink:href="/bundles/app/dist/icons.svg#icon-arrow-left"></use></svg>
        <span class="Pagination-linkLabel">Précédent</span>
      </button>
    </li>`
  }

  const isCurrentPage = (i) => {
    return listParams.currentPage === i ? true : false
  }

  for (let i = 0; i < listParams.pageNb; i++) {
    markup += `<li class="Pagination-item Pagination-item--page`

    if (isCurrentPage(i)) {
      markup += ` Pagination-item--current`
    }

    markup += `">`
    markup += `<button class="Pagination-page Pagination-link"`

    if (isCurrentPage(i)) {
      markup += ` disabled`
    }

    markup += `>${i + 1}</button>`
    markup += `</li>`
  }

  if (listParams.currentPage < listParams.pageNb - 1) {
    markup += `<li class="Pagination-item Pagination-item--next">
      <button class="Pagination-link">
        <span class="Pagination-linkLabel">Suivant</span>
        <svg class="icon icon-arrow-right" aria-hidden="true" focusable="false" width="20" height="20"><use xlink:href="/bundles/app/dist/icons.svg#icon-arrow-right"></use></svg>
      </button>
    </li>`
  }

  markup += `</ul></div></div>`

  pagination.innerHTML = markup

  return pagination
}

function updatePagination() {
  const pagination = document.querySelector('[data-pagination]')
  if (pagination) pagination.remove()
  if (store.structures.length <= listParams.limitPerPage) {
    return
  }

  const markup = paginationMarkup()
  document.querySelector('[data-map-resultsList]').parentNode.append(markup)
  markup.querySelectorAll('.Pagination-item--page button').forEach((item) => {
    item.addEventListener('click', function(e) {
      listParams.currentPage = parseInt(e.currentTarget.innerText) - 1
      updateStructureList()
    })
  })

  const prevButton = markup.querySelector('.Pagination-item--previous button')
  if (prevButton) {
    prevButton.addEventListener('click', function () {
      listParams.currentPage = listParams.currentPage - 1
      if (listParams.currentPage < 0) {
        listParams.currentPage = 0
      }
      updateStructureList()
    })
  }

  const nextButton = markup.querySelector('.Pagination-item--next button')
  if (nextButton) {
    nextButton.addEventListener('click', function () {
      listParams.currentPage = listParams.currentPage + 1
      if (listParams.currentPage >= listParams.pageNb) {
        listParams.currentPage = listParams.pageNb
      }
      updateStructureList()
    })
  }
}

// result markup
function getPrototype(index, structure) {
  let formattedPhoneNumber = structure.phone_number

  if(!document.body.classList.contains('explorer')) {
    formattedPhoneNumber = [...formattedPhoneNumber].map((d, i) => (i) % 2 == 0 ? ' ' + d : d).join('').trim()
  }


  const structure_type = structure.structure_type ? structure.structure_type : ''
  const action_type = structure.action_type ? structure.action_type : ''
  const address2 = structure.address2 ? structure.address2 : ''
  const postcode = structure.postcode.padStart(5, '0')

  return `
    <div class="MapResult">
      <div class="MapResult-container">
        <div class="MapResult-content">
          <div class="MapResult-pinpoint">
            <svg class="icon icon-pinpoint" xmlns="http://www.w3.org/2000/svg" width="30" height="34.69" viewBox="0 0 30 34.69">
              <path fill="currentColor" d="M15,34.69q15-12,15-20.07A14.82,14.82,0,0,0,15,0,14.82,14.82,0,0,0,0,14.62Q0,22.71,15,34.69Z"/>
            </svg>
            <span class="MapResult-index">${index + 1}</span>
          </div>
          <div class="MapResult-infos">
            <span class="MapResult-name">${structure.name}</span>
            <span class="MapResult-structureType">${structure_type}</span>
            <span class="MapResult-structureActionType">${action_type}</span>
            <address class="MapResult-address">
              <span class="MapResult-address1">${structure.address1}</span>
              <span class="MapResult-address2">${address2}</span>
              <span class="MapResult-city">${postcode} ${structure.city}</span>
            </address>
            <a class="MapResult-phone" href="tel:+33${structure.phone_number}">${formattedPhoneNumber}</a>
          </div>
          <div class="MapResult-cta">
            <a class="Button Button--blue Button--outline" href="/structure/${structure.id}">Sélectionner ce lieu</a>
          </div>
        </div>
      </div>
    </div>
  `
}

// Click on list's structure to zoom on the map
function onStructureClick() {
  const structures = document.querySelectorAll('[data-map-result]')

  let index
  let coordinates

  structures.forEach(structure => {
    structure.addEventListener('click', (e) => {
      if (e.target.classList.contains('MapResult-phone') || e.target.classList.contains('Button')) return

      index = structure.querySelector('.MapResult-index').innerText - 1
      coordinates = {
        lat: store.structures[index].latitude,
        lng: store.structures[index].longitude
      }

      structures.forEach(structure => {
        structure.classList.remove('MapResults-result--is-selected')
      })

      if (!e.currentTarget.classList.contains('MapResults-result--is-selected')) {
        e.currentTarget.classList.add('MapResults-result--is-selected')

        map.setCenter(coordinates)
        map.setZoom(mapParams.zoom.max)
        store.infoWindows.forEach(infoWindow => infoWindow.close())
        store.infoWindows[index].open(map, store.markers[index])
      }
    })
  })
}

function updateSearchTitle() {
  const title = document.querySelector('[data-map-resultsTitle]')

  if (!title) return

  if (store.listedStructureCount > 1) {
    title.innerText = `Voici les ${store.listedStructureCount} structures les plus proches`
  } else {
    title.innerText = `Voici la structure la plus proche`
  }
}

export function updateStructureList() {
  const resultsList = document.querySelector('[data-map-resultsList]')
  resultsList.innerHTML = ''

  if (store.structures.length === 0) {
    // Show message
    document.querySelector('[data-map-noResults]').classList.remove('MapResults-noResults--is-hidden')
    document.querySelector('[data-map-resultsTitle]').classList.add('MapResults-resultsHeaderTitle--is-hidden')
  } else {
    // Hide message
    document.querySelector('[data-map-noResults]').classList.add('MapResults-noResults--is-hidden')
    document.querySelector('[data-map-resultsTitle]').classList.remove('MapResults-resultsHeaderTitle--is-hidden')
  }

  listParams.pageNb = Math.ceil(store.structures.length / listParams.limitPerPage)

  let offset = listParams.currentPage * 10
  store.structures.slice(offset, offset + listParams.limitPerPage).forEach((structure, index) => {
    const newStructure = document.createElement('li')
    const newStructureContent = getPrototype(offset + index, structure)

    newStructure.classList.add('MapResults-result')
    newStructure.setAttribute('data-map-result', '')
    newStructure.innerHTML = newStructureContent

    resultsList.appendChild(newStructure)

    store.farthestStructure = structure
    store.listedStructureCount = offset + index + 1
  })

  updateSearchTitle()
  updateMapCenter()
  updateMapZoom()
  updateMapMarkers(offset)
  updateMapInfoWindows(offset)
  onMarkerClick()
  onStructureClick()
  updatePagination()
}
