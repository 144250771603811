var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SPFPlayer-infos" }, [
    _c("p", { staticClass: "SPFPlayer-infosTitle" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "SPFPlayer-infosDate" }, [
      _vm._v(_vm._s(_vm.date)),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "SPFPlayer-infosAuthor" }, [
      _vm._v(_vm._s(_vm.author)),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "SPFPlayer-infosDuration" }, [
      _vm._v(_vm._s(_vm.duration)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }