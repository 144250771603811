import throttle from './../../js/utils/throttle'

function init() {
  const topButton = document.querySelector('[data-top-page]')
  const body = document.body
  let scrollPos = 0

  if (!topButton) return

  window.addEventListener('scroll', throttle(function() {
    scrollPos = window.pageYOffset

    if (scrollPos > window.innerHeight) {
      topButton.classList.add('TopPage--is-visible')
    } else {
      topButton.classList.remove('TopPage--is-visible')
    }
  }, 100))

  topButton.addEventListener('click', () => {
    body.scrollIntoView({
      behavior: 'smooth'
    })
  })
}

export default { init }
