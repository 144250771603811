import { store } from './Map'
import { updateStructureList } from './MapList'


export function fetchStructures() {
  const url = new URL('/structure/search', window.location.origin);
  const parameters = {
    latitude: store.startLocation.lat(),
    longitude: store.startLocation.lng(),
    region: store.input.region
  }

  url.search = new URLSearchParams(parameters)

  const headers = new Headers();
  headers.append('Content-Type', 'application/json')

  fetch(url, {
    headers: headers,
  })
    .then(response => response.json())
    .then(results => {
      store.structures = results
      updateStructureList()
      document.title = "Les espaces dédiés #MoisSansTabac proches de chez vous"
    })
    .catch(error => {
      console.error('Error:', error);
    })
}
