
const ENTERTAINMENT_CARDS_SELECTOR = '[data-card-entertainment]'
const LOAD_MORE_SELECTOR = '[data-loadmore]'

const entertainmentCards = document.querySelectorAll(ENTERTAINMENT_CARDS_SELECTOR)
const loadMoreButton = document.querySelector(LOAD_MORE_SELECTOR)

const baseCardViewNumber = 12
const cardPerView = 9
const cardsArray = Array.prototype.slice.call(entertainmentCards) // convert NodeList into Array

let visibleCards, cardsParentArray

function init () {

  if(!cardsArray.length) return

  // get the parent of the cards
  // Doing this Because in the template the cards are in ul/li inside a foreach and this is the li that has to be hide to prevent his css to interfere 
  // if there is no li comment this line and directly pass cardsArray in the initCards function 
  cardsParentArray = getParent(cardsArray)

  initCards(cardsParentArray, loadMoreButton)
}

function getParent(cardsArray) {

  let cardsParentArray = []

  cardsArray.forEach(card => {

    let cardParent = card.parentElement
    cardsParentArray = [...cardsParentArray, cardParent]

  });

  return cardsParentArray
}

function initCards(cards, loadMoreButton) {

  displayCards(cards, loadMoreButton, baseCardViewNumber)

  if(loadMoreButton){
    loadMoreController(cards, loadMoreButton)
  }

}

function displayCards(cards, button, limit) {

  const cardsLength = cards.length

  if (cardsLength > limit) {

    visibleCards = cards.splice(0, limit)

    hideCards(cards)
    showCards(visibleCards)

  } else{

    showCards(cards)

    if (button) {
      hideButton(button)
    }

  }

}

function hideCards(cards) {
  cards.forEach(card => {
    card.setAttribute('aria-hidden', 'true')
  });
}

function showCards(cards) {
  cards.forEach(card => {
    card.setAttribute('aria-hidden', 'false')
  });
}

function hideButton(button) {
  button.setAttribute('aria-hidden', 'true')
}

function loadMoreController(cards, button) {
  button.addEventListener('click', () => {
    displayCards(cards, button, cardPerView)
  })
}

export default { init }