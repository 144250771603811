function updateUrl() {
    let selectedFilters = [];
    let newUrl;
    const boxes = document.querySelectorAll(`input[name=filter]:checked`);
    const builtLocation = location.protocol + '//' + location.host + location.pathname
    if (!boxes) return;
    boxes.forEach( el => {
        selectedFilters.push(el.id);
    })
    if (selectedFilters.length === 0) {
        newUrl = builtLocation;
   } else {
        newUrl = builtLocation + '?' + 'filter[]=' + selectedFilters.join('&filter[]=');
   }
   window.location.replace(newUrl);
}



function init() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString).getAll('filter[]');
  const filterForm = document.querySelector('#filterForm');

  if (filterForm) {
    const filterFormCheckboxes = filterForm.querySelectorAll('[type*="checkbox"]')
    filterFormCheckboxes.forEach((el) => {
      el.addEventListener('change', (e) => {
        updateUrl();
      })

      if (urlParams.includes(el.id)) {
        document.getElementById(el.id).checked = true;
      }
    })
  }
}

export default { init }
