import CitiesAutocomplete from './../../js/utils/form-cities-autocomplete'
import FormValidate from './../../js/utils/form-validate'

function init() {
  const forms = document.querySelectorAll('[data-form]')

  if (!forms.length) return

  forms.forEach(form => {
    const formValidateInit = new FormValidate(form)
    const citiesAutocompleteInit = new CitiesAutocomplete(form)
  })

  showPassword()
}

function showPassword() {
  const formItems = document.querySelectorAll('[data-formItem="password"]')

  formItems.forEach(item => {
    const passwordInput = item.querySelector('input[type="password"]')
    const toggleButton = item.querySelector('button[type="button"]')
    let passwordHidden = true

    toggleButton.addEventListener('click', () => {
      passwordHidden = !passwordHidden

      if (passwordHidden) {
        toggleButton.classList.remove('password-visible')
        toggleButton.setAttribute('aria-label', 'Afficher le mot de passe')

        passwordInput.setAttribute('type', 'password')
      } else {
        toggleButton.classList.add('password-visible')
        toggleButton.setAttribute('aria-label', 'Masquer le mot de passe')

        passwordInput.setAttribute('type', 'text')
      }
    })
  })
}

export default { init }
