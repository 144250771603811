import { listParams, mapParams, store } from './Map'
import { fetchStructures } from './MapFetchStructures'
import { updateStructureList } from './MapList'


// Init first access map page form  (home form)
export function initHomeForm() {
  const homeFormContainer = document.querySelector('[data-map-home]')
  const searchFormContainer = document.querySelector('[data-map-results]')

  if (!homeFormContainer || !searchFormContainer) return

  const homeForm = homeFormContainer.querySelector('[data-map-form-home]')
  const searchForm = searchFormContainer.querySelector('[data-map-form-search]')

  homeForm.addEventListener('submit', e => {
    e.preventDefault()

    searchForm.querySelector('#address').value = homeForm.querySelector('#address').value
    searchForm.querySelector('#location').value = homeForm.querySelector('#location').value

    homeFormContainer.remove()

    searchFormContainer.classList.remove('MapResults--is-hidden')
    searchForm.querySelector('button[type="submit"]').click()
  })
}

// Init map form
export function initSearchForm() {
  const mapForm = document.querySelector('[data-map-form-search]')
  let inputAddress

  if (!mapForm) return

  const mapFormSubmit = mapForm.querySelector('button[type="submit"]')

  mapFormSubmit.addEventListener('click', e => {
    e.preventDefault()
    store.input = {
      address: mapForm.querySelector('#address').value,
      region: mapForm.querySelector('#location').value
    }
    inputAddress = store.input.address+' '+store.input.region

    listParams.currentPage = 0

    geocodeAddress(inputAddress, fetchStructures)
  })
}

// create and init geocoder to interpret form address
let geocoder

export function initGeocoder() {
  if (typeof google === 'undefined') return

  geocoder = new google.maps.Geocoder()
}

function geocodeAddress(address, callback) {
  geocoder.geocode({ 'address': address}, function(results, status) {
    if (status === 'OK') {
      store.startLocation = results[0].geometry.location
      mapParams.mapCenter = store.startLocation
      callback()
    } else {
      store.structures = []
      updateStructureList()
    }
  })
}
