class PlayerModal {
  constructor(modal) {
    this.modal = modal
    this.triggerElements = null
    this.activeTrigger = null
    this.mainContent = document.querySelector('.site-wrapper')
    this.body = document.querySelector('body')
  }
  

  init() {

    if(!this.modal) return

    //changing position of modal for the blur effect to work
    this.modal.remove()
    document.body.appendChild(this.modal)

    const hasTriggers = this.checkTrigger()

    if(!hasTriggers)return
    this.initModal()

    this.KeyboardCloseModal()
  }

  checkTrigger() {
    const modalId = this.modal.getAttribute('player-data-modal')
    const triggerElements = document.querySelectorAll(`[data-modal-trigger=${modalId}]`)
    this.triggerElements = triggerElements

    return triggerElements.length
  }

  initModal() {
    this.addTriggerListener()
  }

  addTriggerListener() {
    this.triggerElements.forEach(element => {
      element.addEventListener('click', (e) => {
        e.preventDefault()
        this.apparitionHandler(e)
      })
    });
  }

  apparitionHandler() {
    const isHidden = (this.modal.getAttribute('aria-hidden') === 'true')
    
    if(isHidden) {
      this.showModal()
    }else {
      this.hideModal()
    }
  }

  showModal() {
    const videoBtn = this.modal.querySelector('.SPFPlayer-playerButton')
    this.modal.style.display = "block"

    setTimeout(() => {
      this.modal.setAttribute('aria-hidden', 'false')
      this.mainContent.setAttribute('aria-hidden', 'true')
      this.body.style.overflow = "hidden"

      //adding blur effect
      this.mainContent.style.filter = "blur(10px)"

      if (videoBtn) {
        videoBtn.click()
      }
    }, 100);
  }
  
  KeyboardCloseModal() {    
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {

        const isOpen = (this.modal.getAttribute('aria-hidden') === 'true')
        
        if(!isOpen) {
          this.hideModal()
        }
      }
    });
  }

  hideModal() {
    const videoBtn = this.modal.querySelector('.SPFPlayer-playerButton')
    const audioBtn = this.modal.querySelector('.SPFPlayer-playlistButton')
    const video = this.modal.querySelector('video')
    const audio = this.modal.querySelector('audio')

    if (videoBtn) {
      videoBtn.click()
    }

    if (audioBtn) {
      audioBtn.click()
    }

    if (video) {
      video.pause()
    }

    if (audio) {
      audio.pause()
    }

    this.modal.setAttribute('aria-hidden', 'true')
    this.mainContent.setAttribute('aria-hidden', 'false')
    this.body.removeAttribute('style')

    //removing blur effect
    this.mainContent.removeAttribute('style')

    setTimeout(() => {
      this.modal.style.display = "none"
    }, 300);
  }
}

export default PlayerModal
