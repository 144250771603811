import { listParams, mapParams, store } from './Map'

let map

// Create and init google map
export function createMap() {
  const mapContainer = document.querySelector('[data-results-map]')

  if (!mapContainer || typeof google === 'undefined') return

  map = new google.maps.Map(mapContainer, {
    center: { lng: mapParams.mapCenter.lng, lat: mapParams.mapCenter.lat },
    zoom: mapParams.zoom.current
  })

  mapContainer.style.position = "absolute"
  mapContainer.style.top = "0"
  mapContainer.style.left = "0"
  mapContainer.style.width = "100%"
  mapContainer.style.height = "100%"
}

export function updateMapCenter() {
  map.setCenter(mapParams.mapCenter)
}

// Create infowindows and add them to the store
export function updateMapInfoWindows(offset) {
  clearMapInfoWindows()

  store.structures.slice(0, offset + listParams.limitPerPage).forEach(structure => {
    let formattedPhoneNumber = structure.phone_number

    if(!document.body.classList.contains('explorer')) {
      formattedPhoneNumber = [...formattedPhoneNumber].map((d, i) => (i) % 2 == 0 ? ' ' + d : d).join('').trim()
    }

    const postcode = structure.postcode.padStart(5, '0')

    store.infoWindows.push(new google.maps.InfoWindow({
      content: `<div class="MapInfoWindow">
        <span class="MapResult-name">${structure.name}</span>
        <address class="MapResult-address">
          <span class="MapResult-address1">${structure.address1}</span>
          <span class="MapResult-postcode">${postcode}</span>
          <span class="MapResult-city">${structure.city}</span>
        </address>
      </div>`
    }))
  })
}

// Open an infowindow on marker click
export function onMarkerClick() {
  store.markers.forEach((marker, index) => {
    marker.addListener('click', () => {
      store.infoWindows[index].open(map, marker)
    })
  })
}

function clearMapInfoWindows() {
  store.infoWindows.forEach(infoWindow => infoWindow.setMap(null))
  store.infoWindows = []
}

// Create markers and add them to the store
export function updateMapMarkers(offset) {
  clearMapMarkers()

  const icon = {
    path: 'M0,20.34Q15,8.34,15,.27A14.82,14.82,0,0,0,0-14.35,14.82,14.82,0,0,0-15,.27Q-15,8.37,0,20.34Z',
    fillColor: '#CD4F41',
    fillOpacity: 1,
    strokeColor: 'transparent',
    strokeOpacity: 0,
    anchor: new google.maps.Point(0,0),
    scale: 1.3
  }

  store.structures.slice(0, offset + listParams.limitPerPage).forEach((structure, index) => {
    store.markers.push(new google.maps.Marker({
      position: { lat: structure.latitude, lng: structure.longitude },
      map: map,
      title: structure.name,
      label: {
        text: `${index + 1}`,
        color: '#FFF',
        fontFamily: '"Boing", sans-serif',
        fontSize: '18px',
        fontWeight: '700'
      },
      icon: icon,
      zIndex: 53 - index
    }))
  })
}

function clearMapMarkers() {
  store.markers.forEach(marker => marker.setMap(null))
  store.markers = []
}

// update map zoom relative to farthest structure
export function updateMapZoom() {
  if (!store.structures.length) return

  const calculatedZoom = Math.round(getBaseLog(2, 40000 / (store.farthestStructure.distance / 2)))

  mapParams.zoom.current = valueLimit((calculatedZoom - 1), mapParams.zoom.min, mapParams.zoom.max)
  map.setZoom(mapParams.zoom.current)
}

function valueLimit(val, min, max) {
  return val < min ? min : (val > max ? max : val)
}

function getBaseLog(x, y) {
  return Math.log(y) / Math.log(x)
}

export { map }
