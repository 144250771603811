import { openModal } from './../../embed/modal/modal'
import Swiper, { Navigation, Pagination, A11y, Keyboard } from 'swiper'

Swiper.use([Navigation, Pagination, A11y, Keyboard ])


function init() {
  const listsContainer = document.querySelector('[data-blocLists]')

  if (!listsContainer) return

  createListsModal(listsContainer)
  createListsSlider(listsContainer)
  initSkipCarrousel()

}

function createListsSlider(container) {
  const sliderOptions = {
    a11y: {
      enabled: true,
      prevSlideMessage: 'slide précédente',
      nextSlideMessage: 'slide suivante',
      firstSlideMessage: "Première slide",
      lastSlideMessage: "Dernière slide"
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    slidesPerView: 'auto',
    centeredSlides: true,
    watchOverflow: true,
    watchSlidesVisibility: true,
    breakpoints: {
      700: {
        centeredSlides: false
      }
    },
    navigation: {
      nextEl: container.querySelector('[data-blocLists-next]'),
      prevEl: container.querySelector('[data-blocLists-prev]'),
    },
    pagination: {
      el: container.querySelector('.BlocLists-sliderPagination'),
      type: 'bullets',
      dynamicBullets: true,
      bulletElement: 'li'
    },
    on: {
      init: function () {
        this.slides.forEach(slide => {
          const slideLink = slide.querySelector('a')

          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
            if(slideLink){
              slideLink.setAttribute('tabindex', '-1')
            }
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })
      },
    }

  }

  const sliderInit = new Swiper(container, sliderOptions)

  sliderInit.on('transitionEnd', function () {
    this.slides.forEach(slide => {
      const slideLink = slide.querySelector('a')

      if (!slide.classList.contains('swiper-slide-visible')) {
        slide.setAttribute('aria-hidden', 'true')
        if(slideLink){
          slideLink.setAttribute('tabindex', '-1')
        }
      } else {
        slide.setAttribute('aria-hidden', 'false')
        if(slideLink){
          slideLink.removeAttribute('tabindex')
        }
      }
    })

    this.slides[this.activeIndex].focus()
  })

}

function createListsModal(container) {
  const listsContainer = container.querySelector('[data-blocLists-lists]')
  const modal = document.querySelector('[data-modal="partners"]')

  if (!listsContainer || !modal) return

  const lists = listsContainer.querySelectorAll('[data-list]')

  lists.forEach(list => {
    const button = list.querySelector('[data-show-list]')
    const listName = list.getAttribute('data-listTitle')

    createListMarkup(list)

    const modalContent = `
      <div class="BlocLists-listContainer">
        <p class="BlocLists-listName">${listName}</p>
        <div class="BlocLists-listContent">${createListMarkup(list)}</div>
      </div>
    `

    button.addEventListener('click', () => {
      openModal(modal, modalContent)
    })
  })
}

function createListMarkup(list) {
  const listItems = JSON.parse(list.getAttribute('data-list'))

  const listContent = document.createElement('ul')
  listContent.classList.add('BlocLists-list')

  listItems.forEach(listItem => {
    const listElement = document.createElement('li')
    listElement.classList.add('BlocLists-listItem')
    listElement.innerText = listItem

    listContent.appendChild(listElement)
  })

  const tempContent = document.createElement('div')
  tempContent.appendChild(listContent)

  return tempContent.innerHTML
}

function initSkipCarrousel() {
  // skip the carousel for access
  const blocListSkip = document.querySelector('[data-bloclist-skip]')
  const blocListSkipTarget = document.querySelector('[data-bloclist-skip-target]')
  
  if(blocListSkip && blocListSkipTarget ){
    blocListSkip.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        blocListSkipTarget.focus()
      }
    }, true )
  }
}

export default { init }
