import Swiper, { Pagination, A11y, Keyboard } from 'swiper'
import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"
import 'nodelist-foreach-polyfill';

Swiper.use([Pagination, A11y, Keyboard ])


function init() {
  checkTool()

  const cardsContainer = document.querySelectorAll('[data-slider-accordeon]')
  if (!cardsContainer.length) return
  
  if (window.matchMedia("(max-width: 700px)").matches){
    createListsSlider(cardsContainer)
    initSkipCarrousel(cardsContainer)
  }
}

function createListsSlider(container) {
  
  let swiperOption, prevSlide, nextSlide

  container.forEach(function (element) {

    prevSlide = element.querySelector('[data-accordeon-prev]')
    nextSlide = element.querySelector('[data-accordeon-next]')

    if(prevSlide && nextSlide){
      swiperOption = {
        a11y: {
          enabled: true,
          prevSlideMessage: 'slide précédente',
          nextSlideMessage: 'slide suivante',
          firstSlideMessage: "Première slide",
          lastSlideMessage: "Dernière slide"
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        watchOverflow: true,
        watchSlidesVisibility: true,
        breakpoints: {
          700: {
            centeredSlides: false
          }
        },
        pagination: {
          el: element.querySelector('.Accordeon-sliderPagination'),
          type: 'bullets',
          dynamicBullets: true,
          bulletElement: 'li'
        },
        navigation: {
          nextEl: element.querySelector('[data-accordeon-next]'),
          prevEl: element.querySelector('[data-accordeon-prev]'),
        },
        on: {
          init: function () {
            this.slides.forEach(slide => {
              const slideLink = slide.querySelector('a')
    
              if (!slide.classList.contains('swiper-slide-visible')) {
                slide.setAttribute('aria-hidden', 'true')
                if(slideLink){
                  slideLink.setAttribute('tabindex', '-1')
                }
              } else {
                slide.setAttribute('aria-hidden', 'false')
              }
            })
          },
        }
      }
    }else{
      swiperOption = {
        a11y: {
          enabled: true,
          prevSlideMessage: 'slide précédente',
          nextSlideMessage: 'slide suivante',
          firstSlideMessage: "Première slide",
          lastSlideMessage: "Dernière slide"
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        watchOverflow: true,
        watchSlidesVisibility: true,
        breakpoints: {
          700: {
            centeredSlides: false
          }
        },
        pagination: {
          el: element.querySelector('.Accordeon-sliderPagination'),
          type: 'bullets',
          dynamicBullets: true,
          bulletElement: 'li'
        },
        on: {
          init: function () {
            this.slides.forEach(slide => {
              const slideLink = slide.querySelector('a')
    
              if (!slide.classList.contains('swiper-slide-visible')) {
                slide.setAttribute('aria-hidden', 'true')
                if(slideLink){
                  slideLink.setAttribute('tabindex', '-1')
                }
              } else {
                slide.setAttribute('aria-hidden', 'false')
              }
            })
          },
        }
      }
    }




    const listSlider = new Swiper(element, swiperOption);

    listSlider.on('transitionEnd', function () {
      this.slides.forEach(slide => {
        const slideLink = slide.querySelector('a')

        if (!slide.classList.contains('swiper-slide-visible')) {
          slide.setAttribute('aria-hidden', 'true')
          if(slideLink){
            slideLink.setAttribute('tabindex', '-1')
          }
        } else {
          slide.setAttribute('aria-hidden', 'false')
          if(slideLink){
            slideLink.removeAttribute('tabindex')
          }
        }
      })

      this.slides[this.activeIndex].focus()
    })

  })
}

function checkTool() {
  const toolLinks = document.querySelectorAll('[data-tool]')
  if(!toolLinks.length) return

  const baseUrl = window.location.origin

  toolLinks.forEach(element => element.addEventListener("click", async function () {
    const elementClicked = this
    const toolId = elementClicked.getAttribute('data-tool')

    const request = new Request(`${baseUrl}/api/update-user-tool?tool=${toolId}`)
    await fetch(request)
      .then(response => {
        return response.json()
      }).then(function (data) {
        if (data.hasOwnProperty("success")) {
          elementClicked.closest('.Accordeon-details').querySelector('.Accordeon-check').classList.add('Accordeon-check--checked')
        }
      })
  }))
}

function initSkipCarrousel(container){
  // skip the carousel for access

  container.forEach(function (element) {
    const accordeonSkip = element.querySelector('[data-accordeon-skip]')
    const accordeonSkipTarget = element.querySelector('[data-accordeon-skip-target]')
    
    if(accordeonSkip && accordeonSkipTarget ){
      accordeonSkip.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          accordeonSkipTarget.focus()
        }
      }, true )
    }
  });
}

export default { init }
