function init() {
  downloadLink()
}

function downloadLink() {
  const downloadLinks = document.querySelectorAll('[data-tracking="button-download"]')
  if (!downloadLinks.length) return

  const ATTag = new ATInternet.Tracker.Tag();
  downloadLinks.forEach(function (item) {
    item.addEventListener('click', function () {
      var fileName = item.getAttribute('data-tracking-filename')
      var location = item.getAttribute('data-tracking-location')

      return ATTag.click.send({elem:this, name: fileName, chapter1:'Téléchargement_pdf', chapter2: location, level2:'2', type:'download'});
    });
  });
}

export default { init }
