import Bowser from'bowser'

function init() {
  const body = document.body
  const userAgentInfos = Bowser.getParser(window.navigator.userAgent)

  const browser = userAgentInfos
    .getBrowserName({ toLowerCase: true })
    .replace(/\s+/g, '-')
  const os = userAgentInfos
    .getOSName({ toLowerCase: true })
    .replace(/\s+/g, '-')

  body.classList.add(browser)
  body.classList.add(os)
}

export const getBrowser = () => {
  const userAgentInfos = Bowser.getParser(window.navigator.userAgent)

  return userAgentInfos
    .getBrowserName({ toLowerCase: true })
    .replace(/\s+/g, '-')
}

export const getOs = () => {
  const userAgentInfos = Bowser.getParser(window.navigator.userAgent)

  return userAgentInfos
    .getOSName({ toLowerCase: true })
    .replace(/\s+/g, '-')
}

export const getDevice = () => {
  const userAgentInfos = Bowser.getParser(window.navigator.userAgent)

  return userAgentInfos
    .getPlatformType()
}

export default { init }
