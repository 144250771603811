function init() {
  const infoBloc = document.querySelector('[data-infoBloc]')

  if (!infoBloc) return

  const closeButton = infoBloc.querySelector('button')

  closeButton.addEventListener('click', () => {
    infoBloc.classList.add('removing')

    setTimeout(() => {
      infoBloc.remove()
    }, 500)
  })
}

export default { init }
