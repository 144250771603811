import { getCookieValue } from './../../js/utils/cookies'

function init() {
  checkCartValue()
  skipNavigation()
}

function skipNavigation () {
	const navigationSkip = document.querySelector('[data-navigation-skip]')
	const navigationSkipTarget = document.querySelector('[data-navigation-skip-target]')

  if(navigationSkip && navigationSkipTarget ){
    navigationSkip.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        navigationSkipTarget.focus()
      }
    }, true )
  }
}

export function checkCartValue() {
  const cartLinks = document.querySelectorAll('[data-cart-link]')

  if (!cartLinks.length) return

  let cart = getCookieValue('cart_products')

  if(cart !== "" && cart !== "{}") {
    cartLinks.forEach(link => {
      link.classList.add('has-products')
    })
  } else {
    cartLinks.forEach(link => {
      link.classList.remove('has-products')
    })
  }
}

export default { init }
