import tippy from 'tippy.js'
import 'element-remove';

function init() {
  const accountContainer = document.querySelector('[data-account]')

  if (!accountContainer) return

  const accountButton = accountContainer.querySelector('[data-account-button]')
  const accountMenu = accountContainer.querySelector('[data-account-menu]')

  const accountPopupOptions = {
    content: accountMenu.innerHTML,
    allowHTML: true,
    trigger: 'click',
    arrow: false,
    zIndex: 110,
    placement: 'bottom',
    interactive: true,
    aria: {
      content: 'describedby',
    },
    role: '',
    onCreate(instance) {
      instance.reference.setAttribute('aria-controls', `${instance.popper.getAttribute('id')}`)
    },
    theme: 'custom-popup',
    animation: 'fade',
    inertia: true,
    duration: [500, 350],
    maxWidth: 'none',
    offset: [0, 10],
    popperOptions: {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 20,
            altAxis: true,
            tether: false
          }
        }
      ]
    }
  }

  const accountPopup = tippy(accountButton, accountPopupOptions)

  accountMenu.remove()
}

export default { init }
