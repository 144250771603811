import Swiper, { Navigation, Pagination, EffectFade, A11y, Keyboard } from 'swiper'
import testimonialDropdown from './../Testimonial/Testimonial'
import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"

Swiper.use([Navigation, Pagination, EffectFade, A11y, Keyboard ])


function init() {
  const testimonialsCarousel = document.querySelector('[data-testimonials-slider]')

  if (!testimonialsCarousel) return

  const slides = testimonialsCarousel.querySelector('.swiper-slide')
  const testimonialsdropdown = testimonialsCarousel.querySelectorAll('[data-testimonial-dropdown]')
  const mqMediumUp = window.matchMedia("(min-width: 701px)")

  let testimonialsCarouselOptions = {
    a11y: {
      enabled: true,
      prevSlideMessage: 'slide précédente',
      nextSlideMessage: 'slide suivante',
      firstSlideMessage: "Première slide",
      lastSlideMessage: "Dernière slide"
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    slidesPerView: 'auto',
    centeredSlides: true,
    watchSlidesVisibility: true,
    pagination: {
      el: '.TestimonialsCarousel-pagination',
      type: 'bullets'
    },
    on: {
      init: function () {
        this.slides.forEach(slide => {
          const slideButton = slide.querySelector('button')

          if (!slideButton) return

          if (!slide.classList.contains('swiper-slide-active')) {
            slide.setAttribute('aria-hidden', 'true')
            slideButton.setAttribute('tabindex', '-1')
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })
      },
    }
  }

  if (mqMediumUp.matches) {
    testimonialsCarouselOptions = {
      a11y: {
        enabled: true,
        prevSlideMessage: 'slide précédente',
        nextSlideMessage: 'slide suivante',
        firstSlideMessage: "Première slide",
        lastSlideMessage: "Dernière slide"
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      slidesPerView: 1,
      speed: 500,
      loop: true,
      watchSlidesVisibility: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      allowTouchMove: false,
      navigation: {
        nextEl: '.TestimonialsCarousel-nav--next',
        prevEl: '.TestimonialsCarousel-nav--previous',
      },
      pagination: {
        el: '.TestimonialsCarousel-pagination',
        type: 'bullets',
        clickable: true
      },
      on: {
        init: function () {
          this.slides.forEach(slide => {
            const slideButton = slide.querySelector('button')

            if (!slideButton) return

            if (!slide.classList.contains('swiper-slide-active')) {
              slide.setAttribute('aria-hidden', 'true')
              if(slideButton){
                slideButton.setAttribute('tabindex', '-1')
              }
            } else {
              slide.setAttribute('aria-hidden', 'false')
            }
          })
        },
      }
    }
  }

  const testimonialsCarouselInit = new Swiper(testimonialsCarousel, testimonialsCarouselOptions)

  testimonialsCarouselInit.on('transitionEnd', function () {
    this.slides.forEach(slide => {
      const slideButton = slide.querySelector('button')

      if (!slideButton) return

      if (!slide.classList.contains('swiper-slide-active')) {
        slide.setAttribute('aria-hidden', 'true')
        if(slideButton){
          slideButton.setAttribute('tabindex', '-1')
        }
      } else {
        slide.setAttribute('aria-hidden', 'false')
        if(slideButton){
          slideButton.removeAttribute('tabindex')
        }
      }
    })

    this.slides[this.activeIndex].focus()
  })


  const testimonialsCarouselSlides = testimonialsCarouselInit.slides

  testimonialsCarouselSlides.forEach(slide => {
    const testimonial = slide.querySelector('[data-testimonial-dropdown]')
    const testimonialDropdownInit = new testimonialDropdown(testimonial)
  })

  testimonialsCarouselInit.on('slideChange', () => {
    testimonialsdropdown.forEach(testimonial => {
      if (testimonial.classList.contains('Testimonial-dropdown--open')) {
        testimonial.classList.remove('Testimonial-dropdown--open')
        testimonial.querySelector('.Testimonial-moreButton').setAttribute('aria-label', 'Lire la suite')
      }
    })
  })

  testimonialsCarouselInit.on('slideChange', () => {
    const currentSlideIndex = parseInt(testimonialsCarouselInit.activeIndex)
    testimonialsCarouselInit.slides.forEach(slide => {
      const slideIndex = parseInt(slide.getAttribute('aria-label').substring(0, 1))
      const dropdownButton = slide.querySelector('.Testimonial-moreButton')
      if(slideIndex === currentSlideIndex){
        dropdownButton.setAttribute('tabindex', '0')
      }else{
        dropdownButton.setAttribute('tabindex', '-1')
      }
    })
  })

  // skip the carousel for access
  const testimonialSkip = document.querySelector('[data-testimonial-skip]')
  const testimonialSkipTarget = document.querySelector('[data-testimonial-skip-target]')

  if(testimonialSkip && testimonialSkipTarget ){
    testimonialSkip.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        testimonialSkipTarget.focus()
      }
    }, true )
  }

}

export default { init }
