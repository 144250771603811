function init() {
  const body = document.body
  const menuButton = document.querySelector('[data-menuMobileTrigger]')
  const menuOverlay = document.querySelector('[data-menuMobile-overlay]')

  if (!menuButton || !menuOverlay) return

  menuButton.addEventListener('click', () => {
    body.classList.toggle('menu-open--mobile')

    menuButton.getAttribute('aria-expanded') === "true" ? menuButton.setAttribute('aria-expanded', "false") : menuButton.setAttribute('aria-expanded', "true")
  })

  menuOverlay.addEventListener('click', () => {
    body.classList.remove('menu-open--mobile')
  })
}

export default { init }
