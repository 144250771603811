import { openModal } from './../../embed/modal/modal'
import { getCookieValue, setCookie } from './../../js/utils/cookies'
import { checkCartValue } from './../Header/Header'


function init() {

  const products = document.querySelectorAll('[data-product]')

  if (!products.length) return

  products.forEach(product => {
    productPreview(product)

    const addButton = product.querySelector('[data-product-add]')
    const removeButton = product.querySelector('[data-product-remove]')

    if(addButton) {
      updateButtonLabel(product, addButton);
      addButton.addEventListener('click', () => {
        addToCart(product, addButton)
        checkCartValue()
      })
    }

    if(removeButton) {
      removeButton.addEventListener('click', () => {
        removeFromCart(product)
        checkCartValue()
      })
    }
  })
}

function productPreview(product) {
  const toggleButton = product.querySelector('[data-product-previewToggle]')

  if (!toggleButton) return

  const previewContent = product.querySelector('[data-product-preview]')
  const productID = product.getAttribute('data-product')
  const buttonDefaultLabel = toggleButton.innerText

  previewContent.setAttribute('id', `product-preview-${productID}`)
  previewContent.classList.add('is-hidden')
  toggleButton.setAttribute('aria-controls', `product-preview-${productID}`)
  toggleButton.setAttribute('aria-expanded', 'false')

  toggleButton.addEventListener('click', () => {
    if (toggleButton.getAttribute('aria-expanded') === "true") {
      previewContent.classList.add('is-hidden')
      toggleButton.setAttribute('aria-expanded', 'false')
      toggleButton.innerText = buttonDefaultLabel
    } else {
      previewContent.classList.remove('is-hidden')
      toggleButton.setAttribute('aria-expanded', 'true')
      toggleButton.innerText = "- Masquer l'aperçu"
    }
  })
}

function addToCart(product, addButton) {
  let cart = getCookieValue('cart_products')
  if(cart !== "") {
    cart = JSON.parse(cart)
  } else {
    cart = {}
  }

  const productName = product.querySelector('[data-product-name]').innerText
  const quantity = parseInt(product.querySelector('[data-product-quantity]').value)

  const modal = document.querySelector('[data-modal="shop"]')
  let modalContent
  const productId = product.getAttribute('data-product').replace('product_', '')

  if(quantity === 0) {
    delete cart[productId]

    modalContent = `
      <div class="Product-confirm">
        <p class="Product-confirmText">Vos articles ont bien été retirés de votre panier</p>
        <div class="Product-confirmProductInfos">
          <p class="Product-confirmProductName">${productName}</p>
        </div>
      </div>
    `
  } else {
    cart[productId] = quantity

    modalContent = `
      <div class="Product-confirm">
        <p class="Product-confirmText">Vos articles ont bien été ajoutés à votre panier</p>
        <div class="Product-confirmProductInfos">
          <p class="Product-confirmProductName">${productName}</p>
          <span class="Product-confirmProductQuantity">${quantity}</span>
        </div>
      </div>
    `
  }

  setCookie('cart_products', JSON.stringify(cart), 1)

  openModal(modal, modalContent)

  updateButtonLabel(product, addButton)
}

function removeFromCart(product) {
  let cart = getCookieValue('cart_products')
  if(cart !== "") {
    cart = JSON.parse(cart)
  } else {
    cart = {}
  }

  const productName = product.querySelector('[data-product-name]').innerText

  const modal = document.querySelector('[data-modal="cart"]')
  let modalContent

  delete cart[product.getAttribute('data-product')]

  modalContent = `
    <div class="Product-confirm">
      <p class="Product-confirmText">Vos articles ont bien été retirés de votre panier</p>
      <div class="Product-confirmProductInfos">
        <p class="Product-confirmProductName">${productName}</p>
      </div>
    </div>
  `

  setCookie('cart_products', JSON.stringify(cart), 1)

  openModal(modal, modalContent)

  product.remove()
}

function updateButtonLabel(product, addButton) {
  let cart = getCookieValue('cart_products')
  if(cart !== "") {
    cart = JSON.parse(cart)
  } else {
    cart = {}
  }

  if(cart[product.getAttribute('data-product')] !== undefined) {
    addButton.innerText = addButton.getAttribute('data-update-quantity-label')
  } else {
    addButton.innerText = addButton.getAttribute('data-add-label')
  }
}

export default { init }
