'use strict'

const init = () => {
  const prefooterContainer = document.querySelector('.PreFooter')
  if (!prefooterContainer) return

  const prefooterContent =
    prefooterContainer.querySelector('.PreFooter-content')
  const leftPaddle = prefooterContainer.querySelector('.left-paddle')
  const rightPaddle = prefooterContainer.querySelector('.right-paddle')
  const paddles = prefooterContainer.querySelector('.paddles')

  let scrollPerClick = prefooterContent.clientWidth - 100
  let scrollAmount = 0

  const moveLeft = () => {
    if (scrollAmount <= 0) scrollAmount = scrollPerClick

    prefooterContent.scrollTo({
      top: 0,
      left: (scrollAmount -= scrollPerClick),
      behavior: 'smooth',
    })
  }

  const moveRight = () => {
    const rect = prefooterContent.getBoundingClientRect()
    if (scrollAmount + rect.right > prefooterContent.scrollWidth)
      scrollAmount = prefooterContent.scrollWidth - scrollPerClick

    if (
      scrollAmount <=
      prefooterContent.scrollWidth - prefooterContent.clientWidth
    ) {
      prefooterContent.scrollTo({
        top: 0,
        left: (scrollAmount += scrollPerClick),
        behavior: 'smooth',
      })
    }
  }

  window.addEventListener('load', () => {
    isPrefooterOveflowing(prefooterContent)
      ? visiblePaddles(paddles, prefooterContent)
      : hiddenPaddles(paddles, prefooterContent)

    leftPaddle.style.top = `${calculateTopForPaddle(prefooterContainer)}px`
    rightPaddle.style.top = `${calculateTopForPaddle(prefooterContainer)}px`
  })

  addEventListener('resize', () => {
    isPrefooterOveflowing(prefooterContent)
      ? visiblePaddles(paddles, prefooterContent)
      : hiddenPaddles(paddles, prefooterContent)

    scrollPerClick = prefooterContent.clientWidth - 100

    leftPaddle.style.top = `${calculateTopForPaddle(prefooterContainer)}px`
    rightPaddle.style.top = `${calculateTopForPaddle(prefooterContainer)}px`
  })

  leftPaddle.addEventListener('click', moveLeft)
  rightPaddle.addEventListener('click', moveRight)
}

const visiblePaddles = (el, content) => {
  el.classList.remove('hidden')
  content.classList.remove('PreFooter-content--centered')
}

const hiddenPaddles = (el, content) => {
  el.classList.add('hidden')
  content.classList.add('PreFooter-content--centered')
}

const calculateTopForPaddle = (el) => {
  const prefooterTitle = el.querySelector('.PreFooter-title')
  const prefooterImgs = el.querySelectorAll('.CardPreFooter-image')
  const imgHeight =
    Array.from(prefooterImgs).find((img) => img.clientHeight > 0)
      ?.clientHeight ?? 0

  return getElementHeight(prefooterTitle) + Math.round(imgHeight / 2)
}

const getElementHeight = (el) =>
  el.offsetHeight +
  parseInt(window.getComputedStyle(el).marginTop) +
  parseInt(window.getComputedStyle(el).marginBottom)

const isPrefooterOveflowing = (el) => el.scrollWidth > el.clientWidth

export default { init }
