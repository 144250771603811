
const INPUT_CARD_SELECTOR = '[data-input-card]'
const INPUT_CARD_TEXT = '[data-input-card-text]'

function init() {

  const inputCards = document.querySelectorAll(INPUT_CARD_SELECTOR)

  if(!inputCards.length)return 

  initInputCardHeight(inputCards)
  initInputCards(inputCards)

}

function initInputCardHeight(inputCards) {
  inputCards.forEach(card => {

    const isActive = (card.getAttribute('aria-expanded') === 'true')
    const cardText = card.querySelector(INPUT_CARD_TEXT)
    const textHeight = cardText.offsetHeight


    let cardHeight = card.offsetHeight
    card.setAttribute('data-height', cardHeight)
    
    if(!isActive){
      card.style.height = `${cardHeight}px`
    }else{
      card.style.height = `${cardHeight +textHeight -20}px`
    }
  });
}

function initInputCards(inputCards) {
  inputCards.forEach(card => {
    card.addEventListener('click', (e) => {
      toggleActive(card, inputCards)
    })
  });
}

function toggleActive(card, inputCards) {
  const cardText = card.querySelector(INPUT_CARD_TEXT)
  const isActive = (card.getAttribute('aria-expanded') === "true")

  if(!isActive){
    prependInputcards(inputCards)
    
    setTimeout(() => { // to be sure expanded is set to true after all others are set to false
      card.setAttribute('aria-expanded', 'true')
      cardText.setAttribute('aria-hidden', 'false')
      adjustHeight(card, cardText)
    }, 100);
  }
}

function prependInputcards(inputCards) {
  inputCards.forEach(card => {
    card.style.height = `${card.getAttribute('data-height')}px`
    const cardText = card.querySelector(INPUT_CARD_TEXT)
    card.setAttribute('aria-expanded', 'false')
    cardText.setAttribute('aria-hidden', 'true')
  });
}

function adjustHeight(card, cardText) {
  const textHeight = cardText.offsetHeight
  const cardHeight = card.offsetHeight + textHeight
  card.style.height = `${cardHeight + 20}px`
}

export default { init }
