import { getDevice, getOs } from './bowser'


// set the link url according to the operating system
function init() {
  const appLinks = document.querySelectorAll('[data-link]')

  if (!appLinks.length) return

  appLinks.forEach(link => {
    if (link.getAttribute('data-link') === "app-download") {
      if (getOs() === "android") {
        link.setAttribute('href', link.getAttribute('data-url-android'))
      } else if (getOs() === "ios") {
        link.setAttribute('href', link.getAttribute('data-url-ios'))
      }
    } else if (link.getAttribute('data-link') === "3989") {
      if (getDevice() == "mobile") {
        link.setAttribute('href', link.getAttribute('data-url-mobile'))
        link.removeAttribute('target')
        link.removeAttribute('rel')
      }
    }
  })
}



export default { init }
