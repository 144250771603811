const focusables = 'a[href]:not([disabled]), button:not([disabled]), button:not(:disabled), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
function init() {
  const modals = document.querySelectorAll('[data-modal]')

  if (!modals.length) return

  onLoadOpenModal()

  modals.forEach(modal => {
    eventCloseModal(modal)
    initAccess(modal)
  })
}

export function openModal(modal, modalContent) {
  if (modalContent) {
    setModalContent(modal, modalContent)
  }

  document.body.classList.add('modal-open')
  modal.classList.add('is-open')
  focusOnOpen(modal)

}

export function setModalContent(modal, modalContent) {
  const modalInner = modal.querySelector('[data-modalContent]')

  modalInner.innerHTML = modalContent
}

function eventCloseModal(modal) {
  const closeElements = modal.querySelectorAll('[data-modalClose]')

  closeElements.forEach(element => {
    element.addEventListener('click', () => {
      document.body.classList.remove('modal-open')
      modal.classList.remove('is-open')
    })
  })
}

function initAccess(modal) {
  accessCloseModal(modal)
  focusTrap(modal)
}

function accessCloseModal(modal){
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      document.body.classList.remove('modal-open')
      modal.classList.remove('is-open')
    }
  }, true )
}

function focusOnOpen(modal){
  const focusableEls = modal.querySelectorAll(focusables);
  const firstFocusableEl = focusableEls[0];
  firstFocusableEl.focus()
}

function focusTrap(modal) {

  let focusableEls = modal.querySelectorAll(focusables);
  let firstFocusableEl = focusableEls[0];
  let lastFocusableEl = focusableEls[focusableEls.length - 1];
  const KEYCODE_TAB = 9;

  modal.addEventListener('keydown', function(e) {
    const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

    if (!isTabPressed) { 
      return; 
    }

    if ( e.shiftKey ) /* shift + tab */ {

      focusableEls = modal.querySelectorAll(focusables);
      firstFocusableEl = focusableEls[0];
      lastFocusableEl = focusableEls[focusableEls.length - 1];

      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }

    } else /* tab */ {

      focusableEls = modal.querySelectorAll(focusables);
      firstFocusableEl = focusableEls[0];
      lastFocusableEl = focusableEls[focusableEls.length - 1];

      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }

    }
  });
}

function onLoadOpenModal() {
  const modals = document.querySelectorAll('[data-modal-open]')

  modals.forEach(modal => {
    openModal(modal);
  })
}

export default { init }
