import throttle from './../../js/utils/throttle'


export default class testimonialDropdown {
  constructor(item) {
    this.testimonial = item

    this.dropdownCreate = this.dropdownCreate.bind(this)

    this.init()
  }

  dropdownCreate() {
    const paragraphContainer = this.testimonial.querySelector('.Testimonial-content')

    if (!this.testimonial.classList.contains('Testimonial-dropdown--open')) {
      if (!this.testimonial.classList.contains('Testimonial-dropdown--active')) {
        if (paragraphContainer.scrollHeight > paragraphContainer.offsetHeight) {
          this.testimonial.classList.add('Testimonial-dropdown--active')
        }
      } else {
        if (paragraphContainer.scrollHeight <= paragraphContainer.offsetHeight) {
          this.testimonial.classList.remove('Testimonial-dropdown--active')
        }
      }
    }
  }
  
  dropdownListener() {
    const dropdownToggle = this.testimonial.querySelector('.Testimonial-moreButton')
    this.testimonial.setAttribute('aria-expanded', 'false')
    
    dropdownToggle.addEventListener('click', () => {
      this.testimonial.classList.toggle('Testimonial-dropdown--open')
      
      if (dropdownToggle.getAttribute('aria-label') === 'Lire la suite') {
        this.testimonial.setAttribute('aria-expanded', 'true')
        dropdownToggle.setAttribute('aria-label', 'Voir moins')
      } else {
        this.testimonial.setAttribute('aria-expanded', 'false')
        dropdownToggle.setAttribute('aria-label', 'Lire la suite')
      }
    })
  }

  resize() {
    window.addEventListener('resize', throttle(this.dropdownCreate, 100))
  }

  init() {
    this.dropdownCreate()
    this.dropdownListener()
    this.resize()
  }
}
