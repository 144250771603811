const INPUTRANGE_SELECTOR = '[data-input-range]'

const inputRange = document.querySelectorAll(INPUTRANGE_SELECTOR)
const tooltipWidth = 150

let isMoving = false

function init() {
 
  if (!inputRange.length) return 

  initInputs(inputRange)
  
}

function initInputs(containers) {
  containers.forEach(container => {

    const input = container.querySelector('input[type="range"]')
    createMarkup(container, input) // dont put other function before the markup is created or it will throw errors

    const totalStep = parseInt(input.getAttribute('max'))
    const tooltip = container.querySelector('[data-tooltip]')
    const values = container.getAttribute('data-input-values')
    const progressBar = container.querySelector('[data-progress-bar]')
    
    handleTooltips(input, totalStep, tooltip, values)
    handleProgessBar(input, totalStep, progressBar)
    InputBehavior(container, input, totalStep, tooltip, progressBar, values)

  });
}

function createMarkup(container, input) {
  createSteps(container, input)
  createProgressBar(container)
  createTooltip(container)
}

function createSteps(container, input){
  const stepsLength = parseInt(input.getAttribute('max')) + 1
  const StepsContainer = document.createElement("div")
  
  StepsContainer.setAttribute('class', 'Form-range-stepContainer')
  
  for(let i = 0; i < stepsLength; i++) {
    let step = document.createElement("div")
    step.setAttribute('class', 'Form-range-step')
    step.setAttribute('data-step', i)
    StepsContainer.appendChild(step);
  }

  container.appendChild(StepsContainer);

  handleStepStyle(StepsContainer, input)  
}

function handleStepStyle(container, input){
  const steps = container.querySelectorAll('[data-step]')
  const inputValue = input.value

  steps.forEach(function (step, i) {
    if(i <= inputValue){
      step.setAttribute('data-active', "true")
    }else{
      step.setAttribute('data-active', "false")
    }
  });
}

function createProgressBar(container) {
  const progressBar = document.createElement("div")
  progressBar.setAttribute('class', 'Form-inputRange-progress')
  progressBar.setAttribute('data-progress-bar', '')
  container.appendChild(progressBar);
}

function createTooltip(container) {
  const tooltip = document.createElement("div")
  tooltip.setAttribute('class', 'Form-inputRange-tooltip')
  tooltip.setAttribute('data-tooltip', '')
  container.appendChild(tooltip);
}

function handleProgessBar(input, totalStep, progressBar){
  const inputCurrentStep = parseInt(input.value)
  const progressWidthByStep = 100 / totalStep
  const progressBarWidth = `${inputCurrentStep * progressWidthByStep}%`

  progressBar.style.width = progressBarWidth
}

function handleTooltips(input, totalStep, tooltip, values) {
  const valuesArray = JSON.parse(values);
  const inputCurrentStep = parseInt(input.value)
  const tooltiPositionByStep = 100 / totalStep
  
  const tooltiPosition = `calc(${tooltiPositionByStep * inputCurrentStep}% - ${tooltipWidth/2}px)`
  // const tooltiPosition = `${tooltiPositionByStep * inputCurrentStep}%`



  tooltip.style.width = `${tooltipWidth}px`
  tooltip.style.left = tooltiPosition
  tooltip.innerHTML = valuesArray[inputCurrentStep]
}


function InputBehavior(container, input, totalStep, tooltip, progressBar, values) {
  
  let inputValue

  input.addEventListener('mousedown', () =>{
    isMoving = true
    inputValue = input.value
  })
  
  input.addEventListener('mouseup', () =>{
    isMoving = false
    inputValue = input.value
  })

  input.addEventListener('mousemove', () =>{
    if(isMoving){
      if(parseInt(input.value) !== parseInt(inputValue) ){
        handleStepStyle(container, input)
        handleProgessBar(input, totalStep, progressBar)
        handleTooltips(input, totalStep, tooltip, values)
        inputValue = input.value
      }
    }
  })

  input.addEventListener('change', () =>{
    handleStepStyle(container, input)
    handleProgessBar(input, totalStep, progressBar)
    handleTooltips(input, totalStep, tooltip, values)
    inputValue = input.value
  })
}

export default { init }