import Swiper, { Navigation, Pagination, A11y, Keyboard } from 'swiper'

Swiper.use([Navigation, Pagination, A11y, Keyboard ])


function init() {
  const toolsSlider = document.querySelectorAll('[data-toolsSlider]')

  if (!toolsSlider.length) return

  toolsSlider.forEach(slider => {
    const sliderOptions = {
      a11y: {
        enabled: true,
        prevSlideMessage: 'slide précédente',
        nextSlideMessage: 'slide suivante',
        firstSlideMessage: "Première slide",
        lastSlideMessage: "Dernière slide"
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      slidesPerView: 'auto',
      centeredSlides: true,
      watchOverflow: true,
      watchSlidesVisibility: true,
      breakpoints: {
        700: {
          centeredSlides: false
        }
      },
      navigation: {
        nextEl: slider.querySelector('[data-toolsSlider-next]'),
        prevEl: slider.querySelector('[data-toolsSlider-prev]'),
      },
      pagination: {
        el: slider.querySelector('.Tools-sliderPagination'),
        type: 'bullets',
        dynamicBullets: true,
        bulletElement: 'li'
      },
      on: {
        init: function () {
          this.slides.forEach(slide => {
            const slideLink = slide.querySelector('a')
  
            if (!slide.classList.contains('swiper-slide-visible')) {
              slide.setAttribute('aria-hidden', 'true')
              if(slideLink){
                slideLink.setAttribute('tabindex', '-1')
              }
            } else {
              slide.setAttribute('aria-hidden', 'false')
            }
          })
        },
      }  
    }
    
    const sliderInit = new Swiper(slider, sliderOptions)
    
    sliderInit.on('transitionEnd', function () {
      this.slides.forEach(slide => {
        const slideLink = slide.querySelector('a')
        
        if (!slide.classList.contains('swiper-slide-visible')) {
          slide.setAttribute('aria-hidden', 'true')
          if(slideLink){
            slideLink.setAttribute('tabindex', '-1')
          }
        } else {
          slide.setAttribute('aria-hidden', 'false')
          if(slideLink){
            slideLink.removeAttribute('tabindex')
          }
        }
      })

      this.slides[this.activeIndex].focus()
    })

  })

  // skip the carousel for access
  const toolsSkip = document.querySelector('[data-tools-skip]')
  const toolsSkipTarget = document.querySelector('[data-tools-skip-target]')
  
  if(toolsSkip && toolsSkipTarget ){
    toolsSkip.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        toolsSkipTarget.focus()
      }
    }, true )
  }
}

export default { init }
