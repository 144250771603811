import { openModal } from './../../embed/modal/modal'


//MODAL PICTURE SELECTORS
const MODAL_PICTURE_SELECTOR = '[data-modal="userPicture"]'
const MODAL_PICTURE_BUTTONS_SELECTOR = '[data-picture-modal]'

//MODAL DELETE ACCOUNT SELECTORS
const MODAL_DELETE_SELECTOR = '[data-modal="deleteAccount"]'
const MODAL_DELETE_BUTTONS_SELECTOR = '[data-delete-modal]'

//FORM DESCRIPTION SELECTORS
const DESCRIPTION_FORM_SELECTOR = '[data-description-form]'
const DESCRIPTION_FORM_TOGGLE_SELECTOR = '[data-toggle-form]'

//UPLOAD FILE SELECTORS
const UPLOAD_FILE_INPUT_SELECTOR = "[data-file-upload]"
const FILE_INFOS_SELECTOR = "[data-file-infos]"
const FILE_ERROR_SELECTOR = "[data-file-error]"
const MODAL_IMAGE_SELECTOR = "[data-modal-image]"
const MODAL_IMAGE_PREVIEW_SELECTOR = "[data-modal-image-preview]"

function init() {
  const descriptionForm = document.querySelector(DESCRIPTION_FORM_SELECTOR)

  if (descriptionForm) {
    initDescriptionForm(descriptionForm)
  }

  // Picture modal
  const modal = document.querySelector(MODAL_PICTURE_SELECTOR)
  const modalButtons = document.querySelectorAll(MODAL_PICTURE_BUTTONS_SELECTOR)

  // Delete Account modal
  const modalDelete = document.querySelector(MODAL_DELETE_SELECTOR)
  const modalDeleteButtons = document.querySelectorAll(MODAL_DELETE_BUTTONS_SELECTOR)

  // upload file input
  const uploadFileInputSelector = document.querySelector(UPLOAD_FILE_INPUT_SELECTOR)
  const fileInfos = document.querySelector(FILE_INFOS_SELECTOR)
  const fileError = document.querySelector(FILE_ERROR_SELECTOR)
  const modalImage = document.querySelector(MODAL_IMAGE_SELECTOR)
  const modalImagePreview = document.querySelector(MODAL_IMAGE_PREVIEW_SELECTOR)

  // // Init functionnalities
  if(modal){initPictureModal(modal, modalButtons)}
  if(uploadFileInputSelector){initValidateFile(uploadFileInputSelector, fileInfos, fileError, modal, modalImage, modalImagePreview, )}
  if(modalDelete){initDeleteAccountModal(modalDelete, modalDeleteButtons)}
}


// FORM DESCRIPTION FUNCTIONS
function initDescriptionForm(form) {
  const descriptionToggleButtons = form.querySelectorAll(DESCRIPTION_FORM_TOGGLE_SELECTOR)
  const descriptionFormTextarea = form.querySelector('textarea')
  const descriptionTextareaContent = descriptionFormTextarea.value

  descriptionToggleButtons.forEach(toggle => {
    toggle.addEventListener('click', () => {

      if (descriptionFormTextarea.readOnly) {
        form.classList.add('UserProfil-form--active')
        descriptionFormTextarea.readOnly = false
        descriptionFormTextarea.focus()
      } else {
        form.classList.remove('UserProfil-form--active')
        descriptionFormTextarea.readOnly = true
        descriptionFormTextarea.value = descriptionTextareaContent
      }
    })
  })
}


// MODAL USER PICTURE
function initPictureModal(modal, modalButtons) {
  modalButtons.forEach(button => {
    button.addEventListener('click', () => {
      openModal(modal)
    })
  });
}

function initValidateFile(uploadFileInputSelector, fileInfos, fileError, modal, modalImage, modalImagePreview) {

  const imageInfosMessage = fileInfos.innerHTML
  const submitButton = document.querySelector('[data-modal-validate]')
  let previewImageUrl

  uploadFileInputSelector.addEventListener('change', (event) => {
    const target = event.target

  	if (target.files && target.files[0]) {

      /*Maximum allowed size in bytes (1MB Example) Change first operand(multiplier) for your needs*/
      const maxAllowedSize = 1 * 1024 * 1024;
      const infos = `${target.files[0].name}`

      if (target.files[0].size < maxAllowedSize && (target.files[0].type === "image/jpeg" || target.files[0].type == "image/png")) {
        previewImageUrl = URL.createObjectURL(target.files[0])

        console.log(previewImageUrl);

        const previewImage = new Image()
        previewImage.src = previewImageUrl

        modalImagePreview.append(previewImage)
        modalImagePreview.setAttribute('aria-hidden', 'false')
        modalImage.setAttribute('aria-hidden', 'true')

        fileInfos.innerHTML = infos
        fileError.innerHTML = ''

      } else {
        fileInfos.innerHTML = imageInfosMessage
        submitButton.setAttribute('disabled', 'disabled')

        if (target.files[0].type !== "image/jpeg" && target.files[0].type !== "image/png"){
          fileError.innerHTML = 'Seul les types d\'images JPEG et PNG sont acceptées. '
        } else if (target.files[0].size > maxAllowedSize){
          fileError.innerHTML = 'La taille de votre image dépasse 1Mo'
        }

        if (modalImagePreview.getAttribute('aria-hidden') === "false" ){

          modalImagePreview.setAttribute('aria-hidden', 'true')
          modalImage.setAttribute('aria-hidden', 'false')

        }
        uploadFileInputSelector.value = '' // reset value of input
      }
    }
  })

  resetOnCancel(modal, modalImage, modalImagePreview, fileInfos, fileError, imageInfosMessage, previewImageUrl, uploadFileInputSelector)
}

function resetOnCancel(modal, modalImage, modalImagePreview, fileInfos, fileError, imageInfosMessage, previewImageUrl, uploadFileInputSelector) {
  const resetButtons = modal.querySelectorAll('[data-modalclose]')

  resetButtons.forEach(button => {
    button.addEventListener('click',(e)  => {
      modalImage.setAttribute('aria-hidden', 'false')
      modalImagePreview.setAttribute('aria-hidden', 'true')
      modalImagePreview.innerHTML = ""

      fileInfos.innerHTML = imageInfosMessage
      fileError.innerHTML = ''

      uploadFileInputSelector.value = ""
      window.URL.revokeObjectURL(previewImageUrl);
    })
  });
}

// MODAL DELET USER ACCOUNT
function initDeleteAccountModal(modal, modalButtons) {
  modalButtons.forEach(button => {
    button.addEventListener('click', () => {
      openModal(modal)
    })
  });
}

export default { init }
