const HIDDEN_DAYS_SELECTOR = '[data-timeline-hiddendays] [data-timeline-row]'
const PREVIOUS_BUTTON_SELECTOR = "[data-timeline-previous]"
const TIMELINE_CONTAINER_SELECTOR = '[data-timeline-hiddendays]'

const hiddenDays = document.querySelectorAll(HIDDEN_DAYS_SELECTOR)
const previousButton = document.querySelector(PREVIOUS_BUTTON_SELECTOR)
const timelineContainer = document.querySelector(TIMELINE_CONTAINER_SELECTOR)

let programCard

if(timelineContainer){
  programCard = timelineContainer.querySelector('[data-timeline-programcard]')
}

let activeDay = hiddenDays[hiddenDays.length - 1]

function init() {
  if(!hiddenDays.length && !previousButton) return

  initButton(previousButton)
}

function initButton(previousButton) {
  previousButton.addEventListener('click', (e) => {
    showTheDayBefore()
  })
}

function showTheDayBefore() {
  for(let i = 0; i < 3 ; i++ ) {
    if(activeDay.previousElementSibling.classList.contains('TimelineRow')){
      activeDay = activeDay.previousElementSibling

      activeDay.setAttribute('aria-hidden', 'false')
      activeDay.querySelector('.Button').setAttribute('tabindex', 'false')
    } else {
      previousButton.setAttribute('aria-hidden', 'true')
      programCard.setAttribute('aria-hidden', 'false')
    }
  }
}

export default {init}
