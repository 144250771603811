var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "SPFPlayer-player--audioContainer": _vm.mediaType === "audio" },
    },
    [
      _c(
        "div",
        {
          staticClass: "SPFPlayer-player",
          class: {
            "is-playing": _vm.isPlaying,
            "SPFPlayer-player--video": _vm.mediaType === "video",
            "SPFPlayer-player--audio": _vm.mediaType === "audio",
          },
          attrs: { "data-current-media-index": "0" },
        },
        [
          _vm.mediaType === "video"
            ? _c("div", [
                _vm.playButton.isVisible
                  ? _c(
                      "button",
                      {
                        staticClass: "SPFPlayer-playerButton",
                        on: { click: _vm.playMedia },
                      },
                      [
                        _vm.mediaPoster
                          ? _c("img", {
                              attrs: {
                                src: _vm.mediaPoster.src,
                                alt: _vm.mediaPoster.alt,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 512 512",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M256 0C114.833 0 0 114.844 0 256s114.833 256 256 256 256-114.844 256-256S397.167 0 256 0zm101.771 264.969l-149.333 96c-1.75 1.135-3.771 1.698-5.771 1.698-1.75 0-3.521-.438-5.104-1.302C194.125 359.49 192 355.906 192 352V160c0-3.906 2.125-7.49 5.563-9.365 3.375-1.854 7.604-1.74 10.875.396l149.333 96c3.042 1.958 4.896 5.344 4.896 8.969s-1.854 7.01-4.896 8.969z",
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "video",
                  {
                    staticClass: "SPFPlayer-mediaPlayer",
                    attrs: {
                      "data-spf-player-mediaPlayer": "",
                      controls: "",
                      disablePictureInPicture: "",
                      controlsList: "nodownload noplaybackrate",
                    },
                  },
                  [
                    _vm._l(_vm.mediaSources, function (source, index) {
                      return _c("source", {
                        key: "source-" + index,
                        attrs: {
                          src: source.src,
                          type: source.type ? source.type : "",
                        },
                      })
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.mediaSubtitles, function (subtitle, index) {
                      return _c("track", {
                        key: "subtitle-" + index,
                        attrs: {
                          label: subtitle.label ? subtitle.label : "",
                          srclang: subtitle.srcLang ? subtitle.srcLang : "",
                          src: subtitle.src,
                          kind: "subtitles",
                        },
                      })
                    }),
                  ],
                  2
                ),
              ])
            : _vm.mediaType === "audio"
            ? _c("div", [
                _vm.playButton.isVisible
                  ? _c(
                      "button",
                      {
                        staticClass: "SPFPlayer-playlistButton",
                        attrs: { "aria-label": _vm.mediaLabel },
                        on: { click: _vm.toggleMediaState },
                      },
                      [
                        this.medias[0]
                          ? _c("img", {
                              staticClass: "SPFPlayer-mediaPlayerImage",
                              attrs: { src: this.medias[0].image.src },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "audio",
                  {
                    staticClass: "SPFPlayer-mediaPlayer",
                    attrs: {
                      "data-spf-player-mediaPlayer": "",
                      controls: "",
                      disablePictureInPicture: "",
                      controlsList: "nodownload noplaybackrate",
                    },
                  },
                  _vm._l(_vm.mediaSources, function (source, index) {
                    return _c("source", {
                      key: "source-" + index,
                      attrs: {
                        src: source.src,
                        type: source.type ? source.type : "",
                      },
                    })
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }