// ------
// Styles
// ------
import "core-js/stable";
import "normalize.css/normalize.css"
import "./styles/index.scss"

// ---------
// SVG icons
// ---------
import "./icons";

// ------
// vendor
// ------
import "what-input"

// -----
// utils
// -----
import bowser from "./js/utils/bowser"
import dynamicLinks from "./js/utils/dynamic-links"
import inputRange from "./js/utils/input-range"
import cardList from "./js/utils/card-list"
import tracking from "./js/utils/tracking"
import entertainment from "./js/utils/entertainment"

// -----
// Embed
// -----
import modal from "./embed/modal/modal"

// -------
// Regular imports
// -------
import playerModals from "./js/playerModals";

// ----------
// Components
// ----------
import accordeon from "./components/Accordeon/Accordeon"
import account from "./components/Account/Account"
import blocLists from "./components/BlocLists/BlocLists"
import form from "./components/Form/Form"
import header from "./components/Header/Header"
import infoBloc from "./components/InfoBloc/InfoBloc"
import inputCard from "./components/InputCard/InputCard"
import map from "./components/Map/Map"
import menuMobileTrigger from "./components/MenuMobileTrigger/MenuMobileTrigger"
import prefooter from "./components/Prefooter/Prefooter"
import product from "./components/Product/Product"
import sliderCard from "./components/SliderCard/SliderCard"
import shop from "./components/Shop/Shop"
import testFagerstrom from "./components/TestFagerstrom/TestFagerstrom"
import testimonialsCarousel from "./components/TestimonialsCarousel/TestimonialsCarousel";
import timeline from "./components/Timeline/Timeline";
import timelineHeader from "./components/TimelineHeader/TimelineHeader";
import tools from "./components/Tools/Tools"
import topPage from "./components/TopPage/TopPage"
import userProfil from "./components/UserProfil/UserProfil"
import vueAppHandler from './components/SPFPlayer/lib/vueAppHandler';


// Run init function for all files when document is ready
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init)
} else {
  init()
}

function init() {
  accordeon.init()
  account.init()
  blocLists.init()
  bowser.init()
  cardList.init()
  dynamicLinks.init()
  form.init()
  header.init()
  infoBloc.init()
  inputCard.init()
  inputRange.init()
  map.init()
  menuMobileTrigger.init()
  modal.init()
  playerModals.init()
  prefooter.init()
  product.init()
  sliderCard.init()
  shop.init()
  testFagerstrom.init()
  testimonialsCarousel.init()
  timeline.init()
  timelineHeader.init()
  userProfil.init()
  tools.init()
  topPage.init()
  tracking.init()
  entertainment.init()
  vueAppHandler.init();
}
