export default class FormValidate {
  constructor(form) {
    this.form = form
    this.inputs = form.querySelectorAll('input[required]')
    this.textareas = form.querySelectorAll('textarea[required]:not([readonly])')
    this.selects = form.querySelectorAll('select[required]')
    this.allFormItems = [...this.inputs, ...this.textareas, ...this.selects]
    this.submitButton = form.querySelector('[type="submit"]')

    this.init()
  }

  disableSubmitButton() {
    if (this.submitButton && this.allFormItems.length) {
      this.submitButton.setAttribute('disabled', '')
    }
  }

  formButtonState() {
    const hasError = this.allFormItems.some((item) => {
      return !this.checkValidity(item)
    })

    if (hasError) {
      this.submitButton.setAttribute('disabled', '')
    } else {
      this.submitButton.removeAttribute('disabled')
    }
  }

  checkValidity(formElement) {
    let inputValid = true

    inputValid = inputValid && (
      !formElement.validity.valueMissing &&
      !formElement.validity.typeMismatch &&
      !formElement.validity.patternMismatch &&
      !formElement.validity.tooShort &&
      !formElement.validity.tooLong &&
      !(formElement.getAttribute('readonly') === 'readonly' && formElement.value === "")
    )

    if (formElement.type === 'checkbox') {
      inputValid = inputValid && formElement.checked
    }

    return inputValid
  }

  toggleError(formField, checkErrors = true) {
    if (!this.checkValidity(formField) && checkErrors) {
      formField.parentNode.classList.add('has-error')
      formField.parentNode.classList.remove('is-valid')
    } else if (this.checkValidity(formField)) {
      formField.parentNode.classList.remove('has-error')
      formField.parentNode.classList.add('is-valid')
    }
  }

  onLoadCheck() {
    this.allFormItems.forEach(formField => {
      this.toggleError(formField, false)
    })
  }

  checkInputValidityOnEvent() {
    const events = ['input', 'blur']

    this.inputs.forEach(input => {
      const inputType = input.getAttribute('type')

      switch (inputType) {
        case 'text':
        case 'email':
        case 'password':
        case 'number':
        case 'tel':
          events.forEach(event => {
            input.addEventListener(event, (e) => {
              this.toggleError(e.target)
              this.formButtonState()
            })
          })
          break
        case 'checkbox':
        case 'range':
          this.formButtonState()
        case 'file':
        case 'radio':
          input.addEventListener('change', (event) => {
            this.toggleError(event.target)
            this.formButtonState()
          })
        default:
          break
      }
    })
  }

  checkTextareaValidityOnEvent() {
    this.textareas.forEach(textarea => {
      textarea.addEventListener('blur', (event) => {
        this.toggleError(event.target)
        this.formButtonState()
      })
    })
  }

  checkSelectValidityOnEvent() {
    const events = ['change', 'blur']

    this.selects.forEach(select => {
      events.forEach(event => {
        select.addEventListener(event, (event) => {
          this.toggleError(event.target)
          this.formButtonState()
        })
      })
    })
  }

  init() {
    this.disableSubmitButton()
    this.onLoadCheck()
    this.checkInputValidityOnEvent()
    this.checkTextareaValidityOnEvent()
    this.checkSelectValidityOnEvent()
  }
}
