import Swiper, { Navigation, Pagination, A11y, Keyboard } from 'swiper'

Swiper.use([Navigation, Pagination, A11y, Keyboard ])

const SLIDER_SELECTOR = '[data-slidercard]'
const slider = document.querySelector(SLIDER_SELECTOR)

function init() {

  if(!slider) return

  initSlider()
  initSkipCarrousel()
  
}

function initSlider() {


  const swiperCards = new Swiper(slider, {
    a11y: {
      enabled: true,
      prevSlideMessage: 'slide précédente',
      nextSlideMessage: 'slide suivante',
      firstSlideMessage: "Première slide",
      lastSlideMessage: "Dernière slide"
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    watchOverflow: true,
    watchSlidesVisibility: true,
    breakpoints: {
      300: {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 40
      },
      700: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 40
      },
      1100: {
        centeredSlides: false,
        slidesPerView: 4,
        spaceBetween: 40
      }
    },
    navigation: {
      nextEl: slider.querySelector('[data-slidercard-next]'),
      prevEl: slider.querySelector('[data-slidercard-prev]'),
    },
    pagination: {
      el: slider.querySelector('.SliderCard-sliderPagination'),
      type: 'bullets',
      dynamicBullets: true,
      bulletElement: 'li'
    },
    on: {
      init: function () {
        this.slides.forEach(slide => {
          const slideLink = slide.querySelector('a')

          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
            if(slideLink){
              slideLink.setAttribute('tabindex', '-1')
            }
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })
      },
    }

  });

  swiperCards.on('transitionEnd', function () {
    this.slides.forEach(slide => {
      const slideLink = slide.querySelector('a')

      if (!slide.classList.contains('swiper-slide-visible')) {
        slide.setAttribute('aria-hidden', 'true')
        if(slideLink){
          slideLink.setAttribute('tabindex', '-1')
        }
      } else {
        slide.setAttribute('aria-hidden', 'false')
        if(slideLink){
          slideLink.removeAttribute('tabindex')
        }
      }
    })

    this.slides[this.activeIndex].focus()
  })

}

function initSkipCarrousel(){
  // skip the carousel for access
  const sliderCardSkip = document.querySelector('[data-slidercard-skip]')
  const sliderCardSkipTarget = document.querySelector('[data-slidercard-skip-target]')
  
  if(sliderCardSkip && sliderCardSkipTarget ){
    sliderCardSkip.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        sliderCardSkipTarget.focus()
      }
    }, true )
  }
}

export default { init }